import { OptionType } from 'fe-design-base/atoms/Select/Select';

import { toI18n } from 'util/i18n';

import { CandidateInfo } from './types';

const DEFAULT_ROUTE = '/hiring/background_checks';

export const ROUTES = {
  STATUS: `${DEFAULT_ROUTE}/company/application`,
  APPLY: `${DEFAULT_ROUTE}/company/application`,
  CHECKS: `${DEFAULT_ROUTE}/company/checks`,
  CHECKS_PAGINATED: (page: number) =>
    `${DEFAULT_ROUTE}/company/checks?page=${page}`,
  CANDIDATE_APPLY: `${DEFAULT_ROUTE}/company/candidate_check`,
};

export const SLICE_NAME = 'backgroundCheckSlice';

export const standardPriceList = [
  {
    primary: toI18n(
      'hr_pro.background_checks.standard.social_security_tracing'
    ),
  },
  {
    primary: toI18n('hr_pro.background_checks.standard.sex_offender_database'),
  },
  {
    primary: toI18n('hr_pro.background_checks.standard.county'),
  },
  {
    primary: toI18n('hr_pro.background_checks.standard.excluded_parties'),
  },
];

export const proPriceList = [
  {
    primary: toI18n('hr_pro.background_checks.pro.seven_year_unlimited_county'),
  },
  {
    primary: toI18n('hr_pro.background_checks.pro.national_criminal_database'),
  },
  {
    primary: toI18n('hr_pro.background_checks.pro.federal_district'),
  },
  {
    primary: toI18n('hr_pro.background_checks.pro.employment'),
  },
];

export const companyTypes: OptionType[] = [
  {
    label: toI18n(
      'hr_pro.onboarding.company.company_types.sole_proprietorship'
    ),
    value: 'sole_proprietorship',
  },
  {
    label: toI18n('hr_pro.onboarding.company.company_types.partnership'),
    value: 'partnership',
  },
  {
    label: toI18n('hr_pro.onboarding.company.company_types.c_corp'),
    value: 'c_corp',
  },
  {
    label: toI18n('hr_pro.onboarding.company.company_types.s_corp'),
    value: 's_corp',
  },
  {
    label: toI18n('hr_pro.onboarding.company.company_types.llc'),
    value: 'llc',
  },
];
export const industryTypes: OptionType[] = [
  {
    label: toI18n(
      'hr_pro.onboarding.company.industries.agriculture_forestry_fishing_hunting'
    ),
    value: 'agriculture_forestry_fishing_hunting',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.mining'),
    value: 'mining',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.utilities'),
    value: 'utilities',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.construction'),
    value: 'construction',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.manufacturing'),
    value: 'manufacturing',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.wholesale_trade'),
    value: 'wholesale_trade',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.retail_trade'),
    value: 'retail_trade',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.company.industries.transportation_warehousing'
    ),
    value: 'transportation_warehousing',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.information'),
    value: 'information',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.finance_insurance'),
    value: 'finance_insurance',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.company.industries.real_estate_rental_lease'
    ),
    value: 'real_estate_rental_lease',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.company.industries.professional_scientific_technical_services'
    ),
    value: 'professional_scientific_technical_services',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.company.industries.management_of_company_and_enterprises'
    ),
    value: 'management_of_company_and_enterprises',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.company.industries.administrative_support_waste_management_remediation_services'
    ),
    value: 'administrative_support_waste_management_remediation_services',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.educational_services'),
    value: 'educational_services',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.company.industries.healthcare_social_assistance'
    ),
    value: 'healthcare_social_assistance',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.company.industries.arts_entertainment_recreation'
    ),
    value: 'arts_entertainment_recreation',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.company.industries.accommodation_and_food_services'
    ),
    value: 'accommodation_and_food_services',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.other_services'),
    value: 'other_services',
  },
  {
    label: toI18n('hr_pro.onboarding.company.industries.public_administration'),
    value: 'public_administration',
  },
];

export const candidateCheckMockList: CandidateInfo[] = [
  {
    name: 'John Doe',
    package_details: 'Pro',
    status: 'Done',
    score: 'Pass',
    purchase_date: '2023-02-20',
    report: 'View Report',
  },
  {
    name: 'Jane Smith',
    status: 'In progress',
    package_details: 'Pro',
    score: 'Needs attention',
    purchase_date: '2023-02-20',
    report: 'Review',
  },
];

export const ownerTitleSelectOptions: OptionType[] = [
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.ceo'),
    value: 'ceo',
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.cfo'),
    value: 'cfo',
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.coo'),
    value: 'coo',
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.cpa'),
    value: 'cpa',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.chairman_of_the_board'
    ),
    value: 'chairman_of_the_board',
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.conservator'),
    value: 'conservator',
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.controller'),
    value: 'controller',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.corporate_officer'
    ),
    value: 'corporate_officer',
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.director'),
    value: 'director',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.director_of_finance'
    ),
    value: 'director_of_finance',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.director_of_operations'
    ),
    value: 'director_of_operations',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.executive_director'
    ),
    value: 'executive_director',
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.finance_manager'),
    value: 'finance_manager',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.managing_director'
    ),
    value: 'managing_director',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.managing_member_partner_member'
    ),
    value: 'managing_member_partner_member',
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.non_for_profit'),
    value: 'non_for_profit',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.officer_operations_manager'
    ),
    value: 'officer_operations_manager',
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.partner'),
    value: 'partner',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.power_of_attorney'
    ),
    value: 'power_of_attorney',
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.president'),
    value: 'president',
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.principal'),
    value: 'principal',
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.trustee'),
    value: 'trustee',
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.vice_president'),
    value: 'vice_president',
  },
  {
    label: toI18n(
      'hr_pro.onboarding.owner.form.title_options.area_vice_president'
    ),
    value: 'area_vice_president',
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.shareholder'),
    value: 'shareholder',
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.doctor'),
    value: 'doctor',
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.attorney'),
    value: 'attorney',
  },
  {
    label: toI18n('hr_pro.onboarding.owner.form.title_options.treasurer'),
    value: 'treasurer',
  },
];
